body {
  margin: 0;
  font-family: 'Rakkas', cursive, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Indentation styles */
.ql-indent-1 {
  padding-left: 1.5em;
}

.ql-indent-2 {
  padding-left: 3em;
}

.ql-indent-3 {
  padding-left: 4.5em;
}

/* Alignment styles */
.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-align-justify {
  text-align: justify;
}

.separator {
  display: flex;
  gap: 5%;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: grey;
  border-radius: 20%;
}

.post-content img {
  max-height: 600px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.custom-carousel-caption {
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  padding: 15px;
  border-radius: 10px;
  backdrop-filter: blur(5px); /* Glass effect */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); /* Text shadow for better contrast */
}

.custom-carousel-caption h5 {
  font-size: 1.4rem;
  font-weight: bold;
  color: #ffffff;
}

.custom-carousel-caption p {
  font-size: 1rem;
  color: #f8f9fa;
}




